
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { PickupScheduleController } from "@/app/ui/controllers/PickupScheduleController";
import { PickupScheduleApiRequest } from "@/data/payload/api/PickupScheduleApiRequest";
import { ApiStateData } from "@/domain/entities/Api";
import {
  PickupSchedule,
  UpdateStatusPickupSchedule,
} from "@/domain/entities/PickupSchedule";
import { FlagsPermissionPickup } from "@/feature-flags/flag-pickup";
import { Vue } from "vue-class-component";
import formatRepeatDays from "../list/utils";
import debounce from "lodash/debounce";
export default class DetailPickupSchedule extends Vue {
  get isEditAble() {
    return FlagsPermissionPickup.permission_pickup_scheduled_pickup_edit.isEnabled();
  }

  goBack() {
    this.$router.push("/pickup/schedule-list");
  }

  mounted() {
    this.fetchDetail();
  }

  get id() {
    return this.$route.params.id;
  }

  apiDetailData = new ApiStateData();
  detailData = new PickupSchedule();

  async fetchDetail() {
    try {
      MainAppController.showLoading();
      this.apiDetailData.loading = true;
      const resp = await PickupScheduleController.getDetailPickupSchedule(this.id);
      this.detailData = new PickupSchedule(resp);
      this.apiDetailData.errorType = "";
      this.statusActive = this.detailData.pickupScheduleStatus.toLowerCase() === "active";
    } catch (error) {
      this.apiDetailData.errorType =
        parsingErrorResponse(error).type === "internet-error"
          ? "internet"
          : parsingErrorResponse(error).type;
    } finally {
      this.apiDetailData.loading = false;
      MainAppController.closeLoading();
    }
  }
  statusActive = false;

  onUpdateStatusActive(value: boolean) {
    this.statusActive = value;
    this.updateStatus();
  }

  async updateStatus() {
    try {
      MainAppController.showLoading();
      await PickupScheduleController.updateStatusPickupSchedule({
        id: this.id,
        payload: new PickupScheduleApiRequest({
          payload: new UpdateStatusPickupSchedule({
            psStatus: this.statusActive ? "active" : "inactive",
          }),
        }),
      });
      this.fetchDetail();
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(
          error,
          "Gagal Update Status Konfigurasi Jadwal!",
          this.updateStatus
        )
      );
    }
  }

  get formattedDetailRepeatDays() {
    return formatRepeatDays(this.detailData, false);
  }

  goToEdit() {
    this.$router.push(`/pickup/scheduled-pickup/${this.id}/edit`);
  }
}
